import { lazy } from 'react';
import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';
import RequireAuth from './RequireAuth'
import AppLayout from '../components/AppLayout/AppLayout';
import PageNoFound from '../components/PageNotFound/PageNoFound';

const Dashboard = lazy(() => import('../components/Dashboard/Dashboard'));
const SignIn = lazy(() => import('../components/Authentication/SignIn'));
const ForgotPassword = lazy(() => import('../components/Authentication/ForgotPassword'));
const ResetPassword = lazy(() => import('../components/Authentication/ResetPassword'));
const LanguageInterpretersManagement = lazy(() => import('../components/LanguageInterpreters/LanguageInterpretersManagement'));
const GuidesManagementView = lazy(() => import('../components/Guides/GuidesManagementView'));
const ContactManagementView = lazy(() => import('../components/ContactManagement/ContactManagementView'));
const ContentManagementView = lazy(() => import('../components/ContentManagement/ContentManagementView'));

const routes: RouteObject[] = [
    { path: '/', element: <SignIn /> },
    { path: '/dashboard', element: <Dashboard /> },
    { path: '/signIn', element: <SignIn /> },
    { path: '/forgotPassword', element: <ForgotPassword /> },
    { path: '/resetPassword', element: <ResetPassword /> },

    {
        element: <RequireAuth />,
        children: [
            { path: '/interpreters', element: <AppLayout pageTitle="Signer"><LanguageInterpretersManagement /></AppLayout> },
            { path: '/guides', element: <AppLayout pageTitle="Guides"><GuidesManagementView /></AppLayout> },
            { path: '/contactManagement', element: <AppLayout pageTitle="Contact Management"><ContactManagementView /></AppLayout> },
            { path: '/contentManagement', element: <AppLayout pageTitle="Content Management"><ContentManagementView /></AppLayout> },
        ]
    },

    // Fall through catch-all route
    {
        path: '/*',
        element: <PageNoFound />,
    },
];

export const initRouting = () => {
    const router = createBrowserRouter(routes);
    return router;
};