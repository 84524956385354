import { AxiosResponse } from 'axios'
import { makeAPIRequest } from '../utils/request';

class AuthenticationAPI {
  async signIn(params: any): Promise<any> {
    const res: AxiosResponse = await makeAPIRequest({
      method: 'POST',
      url: '/api/v1/auth/login',
      data: params,
    })
    
    localStorage.setItem('accessToken', res.data.token)
    return res.data
  }

  async signInWithOTP(params: any): Promise<any> {
    const res: AxiosResponse = await makeAPIRequest({
      method: 'POST',
      url: '/api/login',
      data: params,
    })
    return res.data
  }

  async verifyOTP(params: any): Promise<any> {
    const res: AxiosResponse = await makeAPIRequest({
      method: 'POST',
      url: '/api/verify-otp',
      data: params,
    })
    localStorage.setItem('access-token', res.data.data.token)
    return res.data
  }

  async forgotPassword(params: any): Promise<any> {
    const res: AxiosResponse = await makeAPIRequest({
      method: 'POST',
      url: '/api/v1/auth/forgot-password',
      data: params,
    })
    return res.data
  }

  async signOut(): Promise<any> {
    localStorage.removeItem('access-token')
    // const res: AxiosResponse = await makeAPIRequest({
    //   method: 'POST',
    //   url: '/api/v1/auth/logout',
    // })
    // return res.data
  }
}

export default new AuthenticationAPI()
