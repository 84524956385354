import { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import { initRouting } from './utils/AppRoutes';

import { createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import typography from './utils/Theme/typography';

import { ProvideAuth } from './hooks/check-auth'
import {AxiosInterceptor} from './utils/request';

import './App.css';

const App = () => {
  const router = initRouting();
  // custom theme
  const theme = createTheme({
    typography,
    palette: {
      primary: {
        main: '#1565d8',
      },
      background: {
        default: '#fff',
        paper: "#fff",
      },
    },
  })
  
  return (
    <ThemeProvider theme={theme}>
      <ProvideAuth>
        <AxiosInterceptor>
          <Suspense>
            <RouterProvider router={router} />
          </Suspense>
        </AxiosInterceptor>
      </ProvideAuth>
    </ThemeProvider>
  );
}

export default App;
