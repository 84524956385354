import { createContext, useContext, useEffect, useState } from 'react'
import { authenticationAPI } from '../apis'
import { getTokenPayload } from '../utils/jwt-util';

interface IUser {
  email?: string
  name: string
  mobile: string
  OrganizationId: string
}

interface IAuthContext {
  isAuthenticated: boolean
  isLoading: boolean
  isSessionExpired: boolean
  error: string | null
  user: IUser | null
  signIn: (username: string, password: string) => any
  verifyOTP: (email: string, otp: string) => any
  signOut: () => void
  setSessionExpired: (value: boolean, message: string) => any

  showSnackbar: boolean,
  snackbarMessage: string | null
  snackbarSeverity: any
  onShowSnackbar: (message: string, severity: string) => any
  onHideSnackbar: () => void

}

export const authContext = createContext<IAuthContext>({} as IAuthContext)

const useProvideAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true)
  const [isSessionExpired, setIsSessionExpired] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [user, setUser] = useState<IUser | null>(null)

  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");


  useEffect(() => {
    const accessToken = localStorage.getItem('access-token')
    if (accessToken) {
      try {
        const jwtPayload = getTokenPayload(accessToken)
        console.log('--------jwtPayload---------', jwtPayload)
        setIsAuthenticated(true)
        setUser(jwtPayload.user)
      } catch (err) {
        setIsAuthenticated(false)
        setUser(null)
      }
    } else {
      setIsAuthenticated(false)
      setUser(null)
    }
  }, [])

  const signIn = async (username: string, password: string) => {
    try {
      setIsLoading(true)
      const res = await authenticationAPI.signIn({
        username,
        password,
      })
      setIsAuthenticated(true)
      setError(null)
      setIsLoading(false)
      return res
    } catch (err: any) {
      setIsAuthenticated(false)
      setError(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  const verifyOTP = async (email: string, otp: string) => {
    try {
      setIsLoading(true)
      const res = await authenticationAPI.verifyOTP({
        email,
        otp
      })
      console.log('-----------------', res)
      setIsAuthenticated(true)
      setUser(res.data.user)
      setError(null)
      setIsLoading(false)
      return res
    } catch (err: any) {
      setIsAuthenticated(false)
      setError(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  const signOut = async () => {
    await authenticationAPI.signOut()
    setIsAuthenticated(false)
    setIsSessionExpired(false)
    setError(null)
    setUser(null)
  }

  const setSessionExpired = async (value: boolean, message: string) => {
    setIsSessionExpired(value);
    setError(message);
  }

  const onShowSnackbar = async (message: string, severity: string) => {
    setShowSnackbar(true);
    setSnackbarMessage(message);
    setSnackbarSeverity(severity)
  }

  const onHideSnackbar = async () => {
    setShowSnackbar(false);
    setSnackbarMessage(null);
    setSnackbarSeverity("success")
  }

  return {
    isAuthenticated,
    isLoading,
    isSessionExpired,
    error,
    user,
    signIn,
    signOut,
    verifyOTP,
    setSessionExpired,

    showSnackbar,
    snackbarMessage,
    snackbarSeverity,
    onShowSnackbar,
    onHideSnackbar
  }
}

interface Props {
  children: React.ReactNode
}

export const ProvideAuth = ({ children }: Props) => {
  const auth = useProvideAuth()

  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => {
  return useContext(authContext)
}
