import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export default function PageNotFound() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh'
      }}
    >
      <Typography variant="h1" style={{fontSize: '50px'}}>
        404
      </Typography>
      <Typography variant="h6" style={{ marginTop: '20px' }}>
        The page you’re looking for doesn’t exist.
      </Typography>
      <Link href="/" variant="body1">
        <Button variant="contained" style={{ marginTop: '15px' }}>Back Home</Button>
      </Link>
    </Box>
  );
}