import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { useAuth } from "../../hooks/check-auth";
import Slide, { SlideProps } from '@mui/material/Slide';

type TransitionProps = Omit<SlideProps, 'direction'>;

function TransitionDown(props: TransitionProps) {
    return <Slide {...props} direction="down" />;
};

const AppLayout = ({ pageTitle, children }: any) => {

    const { showSnackbar, snackbarMessage, snackbarSeverity, onHideSnackbar } = useAuth();
    const [open, setOpen] = React.useState(true)

    return (
        <Box sx={{ display: 'flex' }}>
            <Navbar title={pageTitle} open={open} setOpen={setOpen} />
            <Sidebar open={open} setOpen={setOpen} />

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    width: 'calc(100% - 304px)',
                    mr: 4,
                    ml: 4,
                }}
            >
                <Toolbar style={{height: '96px'}} />
                {children}
                {
                    showSnackbar && (
                    <Snackbar 
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
                        TransitionComponent={TransitionDown}
                        open={true} autoHideDuration={6000} onClose={onHideSnackbar}
                    >
                        <Alert onClose={onHideSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                    )
                }
            </Box>
        </Box>
    )
        
};

export default AppLayout;