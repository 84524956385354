import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LogoutOutlined from '@mui/icons-material/LogoutOutlined';
import PeopleIcon from '@mui/icons-material/People';

import { useAuth } from '../../hooks';
import LOGO from '../../assets/Logo.png';
import {useNavigate} from 'react-router-dom';

const drawerWidth: number = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
                position: 'relative',
                whiteSpace: 'nowrap',
                width: drawerWidth,
                transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

interface SidebarProps {
    open: boolean
    setOpen: (val: boolean) => void
};

const Sidebar = ({ open, setOpen }: SidebarProps) => {

    const navigate = useNavigate();
    const { signOut } = useAuth();
    const toggleDrawer = () => {
        setOpen(!open)
    };

    const mainListItems = (
        <>
            <ListItemButton component="a" href="/interpreters">
                <ListItemIcon>
                    <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Signer" />
            </ListItemButton>
            <ListItemButton component="a" href="/guides">
                <ListItemIcon>
                    <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Guides" />
            </ListItemButton>
            <ListItemButton component="a" href="/contentManagement">
                <ListItemIcon>
                    <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Content Management" />
            </ListItemButton>
            <ListItemButton component="a" href="/contactManagement">
                <ListItemIcon>
                    <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Contact Management" />
            </ListItemButton>
            <ListItemButton component="a" onClick={() => {
                // signOut()
                navigate("/")
            }}>
                <ListItemIcon>
                    <LogoutOutlined />
                </ListItemIcon>
                <ListItemText primary="Logout" />
            </ListItemButton>
        </>
    );

    return (
        <Drawer variant="permanent" open={open}>
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
                <img className='logo-image' src={LOGO} alt='App LOGO' />
                <IconButton style={{float: 'right'}} onClick={toggleDrawer}>
                    <ChevronLeftIcon />
                </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">
                {mainListItems}
            </List>
        </Drawer>
    )

};

export default Sidebar;