import jwtDecode, { JwtPayload } from 'jwt-decode'

interface CustomJwtPayload extends JwtPayload {
    user: any
}

const getTokenPayload = (token: string): any => {
  const jwtPayload = jwtDecode<CustomJwtPayload>(token)
  return jwtPayload
}

// const isValidJWT = (token: string) => {
//   const jwtPayload = jwtDecode<CustomJwtPayload>(token)
//   if (jwtPayload) {
//     return jwtPayload.iss === 'https://accounts.google.com' && jwtPayload.aud === process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID
//   }
//   return false
// }

export {
  getTokenPayload,
  // isValidJWT,
}
